const THEME = {
  // https://www.color-hex.com/color-palette/1279
  bloodRed: '#e31b23',
  keypoints: '#98002e',
  segmentations: '#532e63',
  variant4: '#6f1200',
  identifier: '#551900',

  // https://www.color-hex.com/color-palette/9933
  variant6: '#870029',
  variant7: '#c12149',
  variant8: '#ec8080',
  variant9: '#ffa4c4',
  variant10: '#ffd5ee',

  bloodRedLight: `rgba(227,27,35,0.5)`,
  keypointsLight: `rgba(152,0,46,0.5)`,
  segmentationsLight: `rgba(83,46,99,0.5)`,
  variant4Light: `rgba(111,18,0,0.5)`,
  identifierLight: `rgba(85,25,0,0.5)`,

  variant6Light: `rgba(135,0,41,0.5)`,
  variant7Light: `rgba(193,33,73,0.5)`,
  variant8Light: `rgba(236,128,128,0.5)`,
  variant9Light: `rgba(255,164,196,0.5)`,
  variant10Light: `rgba(255,213,238,0.5)`,

  systole: '#6495ed',
  diastole: '#d97f5e',
  midSystole: '#b3b3cc',
  preDiastole: '#82b366',

  framesPerScrollerPage: 100,
}

export default THEME
