import Toggle from 'react-toggle'

import { Button, ButtonsContainer, ControlsContainer, ToggleContainer, ToggleSectionContainer } from './Controls.style'
import './Controls.style.css'

const Controls = ({ activeSegmentation, showMeasurements, setShowMeasurements, setActiveSegmentation }) => {
  const handleToggleShowMeasurements = () => setShowMeasurements((showMeasurements) => !showMeasurements)
  const handleDeleteOnClick = () =>
    setActiveSegmentation({
      ...activeSegmentation,
      isLabeled: false,
      paths: [],
    })

  return (
    <ControlsContainer>
      <ToggleSectionContainer>
        <ToggleContainer onClick={handleToggleShowMeasurements}>
          <div>Show Measurements</div>
          <Toggle checked={showMeasurements} onClick={handleToggleShowMeasurements} readOnly />
        </ToggleContainer>
      </ToggleSectionContainer>
      <ToggleSectionContainer>
        <ButtonsContainer>
          <Button onClick={handleDeleteOnClick}>Delete</Button>
        </ButtonsContainer>
      </ToggleSectionContainer>
    </ControlsContainer>
  )
}

export default Controls
