import {
  Button,
  ButtonsContainer,
  IdentifierContainer,
  Title,
  TitleContainer,
  ViewsContainer,
  WindowsContainer,
} from './Identifier.style'

const Identifier = ({ dicom, viewports, perspective, setPerspective }) => {
  const type = dicom?.type
  const uniqueWindows = [...new Set(viewports?.map((viewport) => viewport?.window))]

  const windowOrders = ['Long Axis', 'Short Axis', 'Apical', 'Subcostal', 'Suprasternal', 'Miscellaneous']
  const orderedWindows = uniqueWindows.sort((a, b) => {
    const stringA = a.replace(' Color', '')
    const stringB = b.replace(' Color', '')
    const indexA = windowOrders.indexOf(stringA)
    const indexB = windowOrders.indexOf(stringB)

    return indexA - indexB
  })

  const buttons = orderedWindows?.map((window, windowIndex) => {
    const filteredViewports = viewports
      ?.filter((viewport) => viewport?.type === type)
      ?.filter((viewport) => viewport?.window === window)
      ?.map((viewport, viewIndex) => {
        const handleViewportOnClick = () =>
          setPerspective({
            ...perspective,
            dicom: dicom?.uuid,
            viewport: viewport?.uuid,
          })

        return (
          <Button key={viewIndex} onClick={handleViewportOnClick} $isActive={perspective?.viewport === viewport?.uuid}>
            {viewport.view}
          </Button>
        )
      })

    return (
      <ViewsContainer key={windowIndex}>
        <Title>{window}</Title>
        <ButtonsContainer>{filteredViewports}</ButtonsContainer>
      </ViewsContainer>
    )
  })

  return (
    <IdentifierContainer>
      <TitleContainer>Select View</TitleContainer>
      <WindowsContainer>{buttons}</WindowsContainer>
    </IdentifierContainer>
  )
}

export default Identifier
