import { useEffect, useMemo } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useParams } from 'react-router-dom'
import useAxios from 'axios-hooks'

import { Button, ButtonsContainer, ActionsContainer } from './Actions.style'
import {
  DICOM_JOBS,
  DICOM_PHASE_LABELS,
  KEYPOINT_COLLECTIONS,
  PERSPECTIVES,
  SEGMENTATIONS,
} from '../../../../../constants/api'

const Actions = ({
  dicom,
  frames,
  dicomPhases,
  keypointCollections,
  segmentations,
  perspective,
  setDataIsSaving,
  setErrorOccured,
  setPerspectiveChangesAreSaved,
  setDicomPhaseLabelChangesAreSaved,
  setKeypointCollectionsChangesAreSaved,
  setSegmentationChangesAreSaved,
  setDicomJobChangesAreSaved,
  checkIfDicomIsLabeled,
}) => {
  const { annotationSetId } = useParams()

  const [{ loading: upsertDicomJobIsLoading }, upsertDicomJob] = useAxios(DICOM_JOBS, {
    manual: true,
    autoCancel: false,
  })

  const [{ loading: upsertPerspectiveIsLoading }, upsertPerspective] = useAxios(PERSPECTIVES, {
    manual: true,
    autoCancel: false,
  })

  const [{ loading: upsertPhaseLabelsIsLoading }, upsertPhaseLabels] = useAxios(DICOM_PHASE_LABELS, {
    manual: true,
    autoCancel: false,
  })

  const [{ loading: upsertKeypointCollectionsIsLoading }, upsertKeypointCollections] = useAxios(KEYPOINT_COLLECTIONS, {
    manual: true,
    autoCancel: false,
  })

  const [{ loading: upsertSegmentationsIsLoading }, upsertSegmentations] = useAxios(SEGMENTATIONS, {
    manual: true,
    autoCancel: false,
  })

  const dicomPhasesPayload = useMemo(
    () => dicomPhases?.filter((dicomPhase) => dicomPhase?.dicom === dicom?.uuid && !!dicomPhase?.phase),
    [dicom?.uuid, dicomPhases],
  )

  const perspectivePayload = useMemo(
    () => ({
      viewport: perspective?.viewport,
      dicom: dicom?.uuid,
      quantifiability: perspective?.quantifiability,
    }),
    [dicom?.uuid, perspective?.quantifiability, perspective?.viewport],
  )

  const keypointCollectionsPayload = useMemo(() => {
    const frameUuids = frames?.map((frame) => frame?.uuid)
    return keypointCollections
      ?.filter((keypointCollection) => frameUuids?.includes(keypointCollection?.frame))
      ?.map((keypointCollection) => {
        const cardiacCyclePhase = dicomPhases?.find(
          (dicomPhase) => dicomPhase?.frame === keypointCollection?.frame,
        )?.phase
        return {
          ...keypointCollection,
          cardiacCyclePhase,
        }
      })
  }, [dicomPhases, frames, keypointCollections])

  const segmentationsPayload = useMemo(() => {
    const frameUuids = frames?.map((frame) => frame?.uuid)
    return segmentations
      ?.filter((segmentation) => frameUuids?.includes(segmentation?.frame))
      ?.map((segmentation) => {
        const usablePhases = dicomPhases?.filter((dicomPhase) => dicomPhase?.phase !== 'Mid-Systole')
        const cardiacCyclePhase = usablePhases?.find((dicomPhase) => dicomPhase?.frame === segmentation?.frame)?.phase
        return {
          ...segmentation,
          cardiacCyclePhase,
        }
      })
  }, [dicomPhases, frames, segmentations])

  const dicomJobPayload = useMemo(() => {
    const { dicomIsLabeled } = checkIfDicomIsLabeled(dicom)
    return {
      dicom: dicom?.uuid,
      annotationSet: annotationSetId,
      labeled: dicomIsLabeled,
    }
  }, [annotationSetId, checkIfDicomIsLabeled, dicom])

  const dataIsSaving = useMemo(
    () =>
      upsertPerspectiveIsLoading ||
      upsertPhaseLabelsIsLoading ||
      upsertKeypointCollectionsIsLoading ||
      upsertSegmentationsIsLoading ||
      upsertDicomJobIsLoading,
    [
      upsertKeypointCollectionsIsLoading,
      upsertPerspectiveIsLoading,
      upsertPhaseLabelsIsLoading,
      upsertSegmentationsIsLoading,
      upsertDicomJobIsLoading,
    ],
  )

  const handleSaveOnClick = () => {
    setDataIsSaving(true)
    upsertPerspective({
      url: PERSPECTIVES,
      data: perspectivePayload,
      method: 'POST',
    })
      ?.then((r) => setPerspectiveChangesAreSaved(true))
      ?.catch(() => setErrorOccured(true))
    upsertPhaseLabels({
      url: DICOM_PHASE_LABELS,
      data: dicomPhasesPayload,
      method: 'POST',
    })
      ?.then((r) => setDicomPhaseLabelChangesAreSaved(true))
      ?.catch(() => setErrorOccured(true))
    upsertKeypointCollections({
      url: KEYPOINT_COLLECTIONS,
      data: keypointCollectionsPayload,
      method: 'POST',
    })
      ?.then((r) => setKeypointCollectionsChangesAreSaved(true))
      ?.catch(() => setErrorOccured(true))
    upsertSegmentations({
      url: SEGMENTATIONS,
      data: segmentationsPayload,
      method: 'POST',
    })
      ?.then((r) => setSegmentationChangesAreSaved(true))
      ?.catch(() => setErrorOccured(true))
    upsertDicomJob({
      url: DICOM_JOBS,
      data: dicomJobPayload,
      method: 'POST',
    })
      ?.then((r) => setDicomJobChangesAreSaved(true))
      ?.catch(() => setErrorOccured(true))
  }

  useEffect(() => setDataIsSaving(dataIsSaving), [dataIsSaving, setDataIsSaving])

  const handleSkipOnClick = () => {}

  useHotkeys('p', (event) => {
    event?.preventDefault()
    handleSaveOnClick()
  })

  useHotkeys('L', (event) => {
    event?.preventDefault()
    handleSkipOnClick()
  })

  return (
    <ActionsContainer>
      <ButtonsContainer>
        <Button disabled={dataIsSaving} onClick={handleSaveOnClick}>
          (P) Save
        </Button>
        <Button disabled={dataIsSaving} onClick={handleSkipOnClick}>
          (L) Skip
        </Button>
      </ButtonsContainer>
    </ActionsContainer>
  )
}

export default Actions
