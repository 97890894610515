import styled from 'styled-components'

export const ActionsContainer = styled.div.attrs({
  className: 'ActionsContainer',
})`
  background: #ccc;
  display: flex;
  flex-direction: column;
  width: calc(100% - 20px);
  gap: 10px;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
`

export const Button = styled.div.attrs({
  className: 'Button',
})(
  ({ disabled }) => `
    background: ${disabled ? 'none' : '#222'};
    color: ${disabled ? '#999' : '#ccc'};
    border-radius: 5px;
    height: 40px;
    width: calc(50% - 5px);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${disabled ? 'default' : 'pointer'};
    &:hover {
      background: ${disabled ? 'none' : '#444'};;
    }  
  `,
)

export const ButtonsContainer = styled.div.attrs({
  className: 'ButtonsContainer',
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TitleContainer = styled.div.attrs({
  className: 'TitleContainer',
})`
  border-radius: 5px 10px 0 0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`
