import Actions from './Actions'
import Summary from './Summary'
import {
  ActionsContainer,
  Button,
  ButtonsContainer,
  Loader,
  StatsContainer,
  SubHeaderContainer,
  ToolButton,
  ToolContainer,
} from './SubHeader.style'

const SubHeader = ({
  study,
  dicoms,
  checkIfDicomIsLabeled,
  previousButtonIsDisabled,
  nextButtonIsDisabled,
  identifierIsDisabled,
  identifierIsActive,
  setActiveTool,
  showPhaser,
  phaserIsDisabled,
  phaserIsActive,
  showKeypointer,
  keypointerIsDisabled,
  keypointerIsActive,
  showSegmentor,
  segmentorIsDisabled,
  segmentorIsActive,
  dicom,
  frames,
  activeDicomPhaseLabels,
  keypointCollections,
  segmentations,
  activePerspective,
  setDataIsSaving,
  setErrorOccured,
  setPerspectiveChangesAreSaved,
  setDicomPhaseLabelChangesAreSaved,
  setKeypointCollectionsChangesAreSaved,
  setSegmentationChangesAreSaved,
  setDicomJobChangesAreSaved,
  showPageLoader,
}) => {
  return (
    <>
      {showPageLoader ? (
        <Loader />
      ) : (
        <SubHeaderContainer>
          <StatsContainer>
            <Summary study={study} dicoms={dicoms} checkIfDicomIsLabeled={checkIfDicomIsLabeled} />
          </StatsContainer>
          <ButtonsContainer>
            <Button disabled={previousButtonIsDisabled}>(R) PREVIOUS</Button>
            <ToolContainer>
              <ToolButton
                disabled={identifierIsDisabled}
                active={identifierIsActive}
                type="identifier"
                onClick={() => setActiveTool('identifier')}
              >
                Identifier
              </ToolButton>
              {showPhaser && (
                <ToolButton
                  disabled={phaserIsDisabled}
                  active={phaserIsActive}
                  type="phaser"
                  onClick={() => setActiveTool('phaser')}
                >
                  Phaser
                </ToolButton>
              )}
              {showKeypointer && (
                <ToolButton
                  disabled={keypointerIsDisabled}
                  active={keypointerIsActive}
                  type="keypointer"
                  onClick={() => setActiveTool('keypointer')}
                >
                  Keypointer
                </ToolButton>
              )}
              {showSegmentor && (
                <ToolButton
                  disabled={segmentorIsDisabled}
                  active={segmentorIsActive}
                  type="segmentor"
                  onClick={() => setActiveTool('segmentor')}
                >
                  Segmentor
                </ToolButton>
              )}
            </ToolContainer>
            <Button disabled={nextButtonIsDisabled}>(Y) NEXT</Button>
          </ButtonsContainer>
          <ActionsContainer>
            <Actions
              dicom={dicom}
              frames={frames}
              dicomPhases={activeDicomPhaseLabels}
              keypointCollections={keypointCollections}
              segmentations={segmentations}
              perspective={activePerspective}
              setDataIsSaving={setDataIsSaving}
              setErrorOccured={setErrorOccured}
              setPerspectiveChangesAreSaved={setPerspectiveChangesAreSaved}
              setDicomPhaseLabelChangesAreSaved={setDicomPhaseLabelChangesAreSaved}
              setKeypointCollectionsChangesAreSaved={setKeypointCollectionsChangesAreSaved}
              setSegmentationChangesAreSaved={setSegmentationChangesAreSaved}
              setDicomJobChangesAreSaved={setDicomJobChangesAreSaved}
              checkIfDicomIsLabeled={checkIfDicomIsLabeled}
            />
          </ActionsContainer>
        </SubHeaderContainer>
      )}
    </>
  )
}

export default SubHeader
