import Toggle from 'react-toggle'

import { Button, ButtonsContainer, ControlsContainer, ToggleContainer, ToggleSectionContainer } from './Controls.style'
import './Controls.style.css'

const Controls = ({ showMeasurements, setShowMeasurements, setKeypoints, activeKeypoints }) => {
  const handleToggleShowMeasurements = () => setShowMeasurements((showMeasurements) => !showMeasurements)
  const handleDeleteOnClick = () =>
    setKeypoints(
      activeKeypoints?.map((keypoint, index) => ({
        ...keypoint,
        x: null,
        y: null,
        isLabeled: false,
        isActive: index === 0,
      })),
    )

  return (
    <ControlsContainer>
      <ToggleSectionContainer>
        <ToggleContainer onClick={handleToggleShowMeasurements}>
          <div>Show Measurements</div>
          <Toggle checked={showMeasurements} onClick={handleToggleShowMeasurements} readOnly />
        </ToggleContainer>
      </ToggleSectionContainer>
      <ToggleSectionContainer>
        <ButtonsContainer>
          <Button onClick={handleDeleteOnClick}>Delete</Button>
        </ButtonsContainer>
      </ToggleSectionContainer>
    </ControlsContainer>
  )
}

export default Controls
