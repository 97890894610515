import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useHotkeys } from 'react-hotkeys-hook'

import Frame from './Frame'
import Scroller from './Scroller'
import Controls from './Controls'
import KeypointerCanvas from './KeypointerCanvas'
import { API_ICARDIO } from '../../../../constants/env.const'
import {
  Button,
  CanvasContainer,
  ContentContainer,
  DicomLink,
  FooterContainer,
  FramesWrapper,
  TitleContainer,
} from './Canvas.style'
import SegmentationCanvas from './SegmentationCanvas'

const Canvas = ({
  study,
  dicom,
  dicoms,
  changesAreSaved,
  frames,
  playVideo,
  pauseVideo,
  videoState,
  activeFrame,
  activeKeypoints,
  setFrames,
  setActiveFrame,
  setKeypoints,
  showMeasurements,
  dicomPhases,
  keypointerIsActive,
  segmentorIsActive,
  activeSegmentation,
  setActiveSegmentation,
}) => {
  const navigate = useNavigate()

  const { annotationSetId, studyId } = useParams()

  const [scale, setScale] = useState(1)
  const [inFrame, setInFrame] = useState(false)

  const numberOfFrames = frames?.length
  const ativeDicomIndex = dicoms?.findIndex((dicomInArray) => dicomInArray.uuid === dicom?.uuid)
  const previousDicom = ativeDicomIndex > 0 ? dicoms?.[ativeDicomIndex - 1] : null
  const nextDicom = ativeDicomIndex < dicoms?.length - 1 ? dicoms?.[ativeDicomIndex + 1] : null

  const previousButtonIsEnabled = dicom?.index > 0
  const nextButtonIsEnabled = dicom?.index < (study?.NumberOfDicoms - 1)

  const canvasFrames = useMemo(
    () =>
      frames
        ?.sort((a, b) => a?.index - b?.index)
        ?.map((frame) => {
          const isActive = frame?.index === activeFrame?.index
          const setFrame = (newFrame) =>
            setFrames((frames) => [...frames?.slice(0, frame?.index), newFrame, ...frames?.slice(frame?.index + 1)])
          return (
            <Frame
              key={frame?.uuid}
              dicom={dicom}
              frame={frame}
              isActive={isActive}
              scale={scale}
              setFrame={setFrame}
            />
          )
        }),
    [activeFrame?.index, dicom, frames, scale, setFrames],
  )

  const dicomLink = (
    <DicomLink href={`${API_ICARDIO}/almanac?dicom=${dicom?.uuid}`} target="_blank">
      {dicom?.uuid}
    </DicomLink>
  )

  useEffect(() => {
    const handleScroll = (event) => {
      if (!inFrame) return

      event?.preventDefault()
      event?.stopPropagation()

      const direction = event.deltaY
      // Adjust these values based on how fast you want the zoom to happen
      const zoomIntensity = 0.1
      let newScale = scale

      if (direction < 0) {
        // Scroll up, zoom in
        newScale += zoomIntensity
        if (newScale > 2) newScale = 2 // Prevent zooming in beyond 10x (arbitrary value, adjust as needed
      } else {
        // Scroll down, zoom out
        newScale -= zoomIntensity
        if (newScale < 1) newScale = 1 // Prevent zooming out beyond the original size
      }

      setScale(newScale)
    }

    window.addEventListener('wheel', handleScroll, { passive: false })

    return () => {
      window.removeEventListener('wheel', handleScroll)
    }
  }, [scale, inFrame])

  const handlePrev = () => {
    if (!previousButtonIsEnabled) return
    if (!changesAreSaved && !window.confirm('You have unsaved changes on this dicom, continue?')) return
    const activeDicom = dicom
    const activeDicomIndex = dicoms?.findIndex((dicom) => dicom?.uuid === activeDicom?.uuid)
    const previousDicom = dicoms?.[activeDicomIndex - 1]
    const dicomLink = `/studies/${annotationSetId}/${studyId}/${previousDicom?.uuid}`
    navigate(dicomLink)
  }

  const handleNext = () => {
    if (!nextButtonIsEnabled) return
    if (!changesAreSaved && !window.confirm('You have unsaved changes on this dicom, continue?')) return
    const activeDicom = dicom
    const activeDicomIndex = dicoms?.findIndex((dicom) => dicom?.uuid === activeDicom?.uuid)
    const nextDicom = dicoms?.[activeDicomIndex + 1]
    const dicomLink = `/studies/${annotationSetId}/${studyId}/${nextDicom?.uuid}`
    navigate(dicomLink)
  }

  useHotkeys('F', (event) => {
    event?.preventDefault()
    handlePrev()
  })

  useHotkeys('H', (event) => {
    event?.preventDefault()
    handleNext()
  })

  return (
    <CanvasContainer>
      <TitleContainer>
        <Button disabled={!previousButtonIsEnabled} onClick={handlePrev}>
          (F) {previousButtonIsEnabled ? `[${String(previousDicom?.index + 1).padStart(2, '0')}]` : ''} PREVIOUS
        </Button>
        <div>
          [{dicom?.index + 1}/{study?.numberOfDicoms}] {dicomLink} - [{activeFrame?.index + 1}/{frames?.length}]{' '}
          {activeFrame?.uuid}
        </div>
        <Button disabled={!nextButtonIsEnabled} onClick={handleNext}>
          (H) {nextButtonIsEnabled ? `[${String(nextDicom?.index + 1).padStart(2, '0')}]` : ''} NEXT
        </Button>
      </TitleContainer>
      <ContentContainer onMouseEnter={() => setInFrame(true)} onMouseLeave={() => setInFrame(false)}>
        <FramesWrapper>{canvasFrames}</FramesWrapper>
        {keypointerIsActive && (
          <KeypointerCanvas
            activeFrame={activeFrame?.index}
            activeKeypoints={activeKeypoints}
            dicom={dicom}
            scale={scale}
            setKeypoints={setKeypoints}
            showMeasurements={showMeasurements}
          />
        )}
        {segmentorIsActive && (
          <SegmentationCanvas
            activeFrameIndex={activeFrame?.index}
            activeSegmentation={activeSegmentation}
            dicom={dicom}
            scale={scale}
            showMeasurements={showMeasurements}
            setActiveSegmentation={setActiveSegmentation}
          />
        )}
      </ContentContainer>
      <FooterContainer>
        {numberOfFrames > 1 ? (
          <>
            <Scroller
              pauseVideo={pauseVideo}
              frames={frames}
              activeFrame={activeFrame?.index}
              setActiveFrame={setActiveFrame}
              dicomPhases={dicomPhases}
            />
            <Controls
              playVideo={playVideo}
              pauseVideo={pauseVideo}
              videoState={videoState}
              setActiveFrame={setActiveFrame}
              numberOfFrames={numberOfFrames}
            />
          </>
        ) : (
          <div style={{ height: 85 }}></div>
        )}
      </FooterContainer>
    </CanvasContainer>
  )
}

export default Canvas
