import Labels from './Labels'
import Controls from './Controls'
import { TitleContainer } from './Segmentor.style'

const Segmentor = ({
  activeFrame,
  activeFeature,
  activeSegmentation,
  dicom,
  dicomPhases,
  features,
  segmentations,
  setActiveFeature,
  setActiveFrameIndex,
  setActiveSegmentation,
  setSegmentations,
  showMeasurements,
  setShowMeasurements,
}) => {
  return (
    <div>
      <TitleContainer>Segment Dicom</TitleContainer>
      <Labels
        activeFrame={activeFrame}
        activeFeature={activeFeature}
        dicom={dicom}
        dicomPhases={dicomPhases}
        features={features}
        setActiveFrameIndex={setActiveFrameIndex}
        setActiveFeature={setActiveFeature}
        segmentations={segmentations}
        setSegmentations={setSegmentations}
      />
      <Controls
        activeSegmentation={activeSegmentation}
        showMeasurements={showMeasurements}
        setShowMeasurements={setShowMeasurements}
        setActiveSegmentation={setActiveSegmentation}
      />
    </div>
  )
}

export default Segmentor
