// Scroller.js
import React, { useState, useEffect } from 'react';
import {
  FramePreviewContainer,
  ScrollerContainer,
  PaginationContainer,
  PageLabel,
} from './Scroller.style';
import THEME from '../../../../../constants/theme';

const Scroller = ({ frames, activeFrame, pauseVideo, setActiveFrame, dicomPhases }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const framesPerPage = THEME.framesPerScrollerPage;

  const totalPages = Math.ceil(frames?.length / framesPerPage);
  const shouldPaginate = frames.length > framesPerPage;

  const paginatedFrames = frames
    ?.sort((a, b) => a?.index - b?.index)
    ?.slice(currentPage * framesPerPage, (currentPage + 1) * framesPerPage);

  const systolicFrameIndex = dicomPhases?.find((dicomPhase) => dicomPhase?.phase === 'Systolic')?.frameIndex;
  const diastolicFrameIndex = dicomPhases?.find((dicomPhase) => dicomPhase?.phase === 'Diastolic')?.frameIndex;
  const midSystoleFrameIndex = dicomPhases?.find((dicomPhase) => dicomPhase?.phase === 'Mid-Systole')?.frameIndex;

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const framePreviews = [
    shouldPaginate && (
      <FramePreviewContainer
        key="previous-button"
        onClick={handlePreviousPage}
        isDisabled={currentPage === 0}
      >
        &laquo;
      </FramePreviewContainer>
    ),

    ...paginatedFrames.map((frame) => {
      const handleFrameOnClick = () => {
        setActiveFrame(frame?.index);
        pauseVideo();
      };
      const isActive = activeFrame === frame?.index;
      const isSystolic = systolicFrameIndex === frame?.index;
      const isDiastolic = diastolicFrameIndex === frame?.index;
      const ismidSystole = midSystoleFrameIndex === frame?.index;

      const renderedIndex = frame?.index + 1;
      const lineOne = renderedIndex < 10 ? '0' : renderedIndex.toString()[0];
      const lineTwo = renderedIndex < 10 ? renderedIndex.toString() : renderedIndex.toString()[1];

      return (
        <FramePreviewContainer
          key={frame?.uuid}
          isActive={isActive}
          isSystolic={isSystolic}
          isDiastolic={isDiastolic}
          ismidSystole={ismidSystole}
          onClick={handleFrameOnClick}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>{lineOne}</div>
            <div>{lineTwo}</div>
          </div>
        </FramePreviewContainer>
      );
    }),

    shouldPaginate && (
      <FramePreviewContainer
        key="next-button"
        onClick={handleNextPage}
        isDisabled={currentPage === totalPages - 1}
      >
        &raquo;
      </FramePreviewContainer>
    ),
  ].filter(Boolean);

  // Update currentPage only if activeFrame changes, and set it to the page containing the active frame
  useEffect(() => {
    const targetPage = Math.floor(activeFrame / framesPerPage);
    if (targetPage !== currentPage) {
      setCurrentPage(targetPage);
    }
  }, [activeFrame, framesPerPage]);

  return (
    <div style={{ width: '100%' }}>
      <ScrollerContainer>
        {framePreviews}
      </ScrollerContainer>
      {shouldPaginate && (
        <PaginationContainer>
          <PageLabel>
            Page {currentPage + 1} of {totalPages}
          </PageLabel>
        </PaginationContainer>
      )}
    </div>
  );
};

export default Scroller;
