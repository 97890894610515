import styled from 'styled-components'
import THEME from '../../../../../constants/theme'

export const ScrollerContainer = styled.div.attrs({
  className: 'ScrollerContainer',
})`
  width: 100%;
  // background: white;
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  min-height: 37px;
  height: 37px;
`

export const FramePreviewContainer = styled.div.attrs({
  className: 'FramePreviewContainer',
})(
  ({ isActive, isSystolic, isDiastolic, ismidSystole, isPreDiastole, isDisabled }) => `
    background: ${
      isSystolic
        ? THEME.systole
        : isDiastolic
        ? THEME.diastole
        : ismidSystole
        ? THEME.midSystole
        : isPreDiastole
        ? THEME.preDiastole
        : '#ccc'
    };
    opacity: ${isActive ? 1 : isDisabled ? 0.5 : 0.7};
    border: 1px solid black;
    border-right: 0;
    padding: 2px 0;
    flex: 1;
    height: 35px;
    cursor: ${isDisabled ? 'default' : 'pointer'};
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      opacity: ${isDisabled ? 0.5 : 1};
    }
    &:first-child {
      // border-left: 0;
      border-radius: 5px 0 0 5px;
    }
    &:last-child {
      border-radius: 0 5px 5px 0;
      border-right: 1px solid black;
    }
  `
);

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 100%;
`;

export const PageLabel = styled.span`
  font-size: 14px;
`;
