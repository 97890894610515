import { useCallback, useEffect, useRef, useState } from 'react'
import { Canvas, Path, PencilBrush } from 'fabric'
import { EraserBrush } from 'erase2d'

import { SegmentationCanvasContainer, DrawSection, WrapDrawControl, Button } from './SegmentationCanvas.style'

const combineObjectsWithUnion = (canvasRef) => {
  const canvas = canvasRef.current
  const pathObjects = canvas.getObjects() // Get all objects

  if (pathObjects.length < 2) return // Only combine if there are at least two objects

  // Combine all path data into a single path string
  const combinedPathData = pathObjects.reduce((accumulatedPath, currentPath) => {
    const pathData = currentPath.path // Get the path data from the current fabric.Path object
    return accumulatedPath.concat(pathData) // Combine the path data
  }, [])

  // Create a new fabric.Path object using the combined path data
  const combinedPath = new Path(combinedPathData, {
    fill: '#ff000082',
    strokeWidth: 1,
    color: '#03a9f4',
    selectable: true,
    lockMovementX: true,
    lockMovementY: true,
  })

  // Remove the old Path objects from the canvas
  pathObjects.forEach((obj) => {
    canvas.remove(obj)
  })

  // Add the new combined Path object to the canvas
  canvas.add(combinedPath)
  canvas.renderAll() // Re-render the canvas
}

const SegmentationCanvas = ({
  activeFrameIndex,
  activeSegmentation,
  setActiveSegmentation,
  dicom,
  scale,
  showMeasurements,
}) => {
  const fabricRef = useRef(null)
  const [activeMode, setActiveMode] = useState('draw')

  const [history, setHistory] = useState([])
  const [mods, setModes] = useState(0)

  const canvas = fabricRef.current

  const drawModeIsActive = activeMode === 'draw'
  const eraseModeIsActive = activeMode === 'erase'

  const updateModifications = useCallback(
    (savehistory) => {
      if (savehistory === true) {
        const myjson = JSON.stringify(fabricRef.current)
        const origin = JSON.parse(JSON.stringify(history))
        origin.push(myjson)
        setHistory(origin)
      }
    },
    [history],
  )

  const handleDrawModeOnClick = () => {
    setActiveMode('draw')
    canvas.isDrawingMode = true
    canvas.freeDrawingBrush = new PencilBrush(canvas)
    canvas.freeDrawingBrush.color = '#03a9f4'
    canvas.freeDrawingBrush.width = 1
    canvas.freeDrawingBrush.strokeDashArray = [10]
    canvas.freeDrawingBrush.fill = '#ff000082'
    canvas.freeDrawingBrush.id = 'pencil'
  }

  const handleEraseModeOnClick = () => {
    setActiveMode('erase')
    const eraseMode = new EraserBrush(fabricRef.current)
    eraseMode.width = 30

    console.log(eraseMode)

    eraseMode.on('start', (e) => {
      // inform something
    })

    eraseMode.on('end', (e) => {
      // e.preventDefault()
      eraseMode.commit(e.detail)
      fabricRef.current.renderAll()
      updateModifications(true)
    })

    fabricRef.current.freeDrawingBrush = eraseMode
    fabricRef.current.freeDrawingBrush.id = 'eraseMode'
  }

  const handleUndoOnClick = () => {
    if (mods < history.length) {
      fabricRef.current.clear()
      fabricRef.current.renderAll()
      if (mods > 0) {
        fabricRef.current.loadFromJSON(history[history.length - 1 - mods - 1])
      }
      setTimeout(() => {
        fabricRef.current.getObjects().forEach((o) => {
          o.fill = '#ff0000c4'
          o.strokeDashArray = []
          o.stroke = '#ff0000c4'
          o.erasable = true
        })
        fabricRef.current.renderAll()
      }, 100)
      setModes((_mods) => (_mods += 1))
    }
  }

  const handleRedoOnClick = () => {
    if (mods > 0) {
      fabricRef.current.clear()
      fabricRef.current.renderAll()
      fabricRef.current.loadFromJSON(history[history.length - 1 - mods + 1])
      setTimeout(() => {
        fabricRef.current.getObjects().forEach((o) => {
          o.fill = '#ff0000c4'
          o.strokeDashArray = []
          o.stroke = '#ff0000c4'
          o.erasable = true
        })
        fabricRef.current.renderAll()
      }, 100)
      setModes((_mods) => (_mods -= 1))
    }
  }

  // const handleControlToggle = (type) => {
  //   fabricRef.current.isDrawingMode = true
  //   switch (type) {
  //     case 'eraseMode':
  //       setEraseModeIsActive(true)
  //       const eraseMode = new EraserBrush(fabricRef.current)
  //       eraseMode.width = 30

  //       eraseMode.on('start', (e) => {
  //         // inform something
  //       })

  //       eraseMode.on('end', (e) => {
  //         // e.preventDefault()
  //         eraseMode.commit(e.detail)
  //         fabricRef.current.renderAll()
  //         updateModifications(true)
  //       })

  //       fabricRef.current.freeDrawingBrush = eraseMode
  //       fabricRef.current.freeDrawingBrush.id = 'eraseMode'
  //       break

  //     default:
  //       setEraseModeIsActive(false)
  //       fabricRef.current.freeDrawingBrush = new PencilBrush(fabricRef.current)
  //       fabricRef.current.freeDrawingBrush.color = '#03a9f4'
  //       fabricRef.current.freeDrawingBrush.width = 1
  //       fabricRef.current.freeDrawingBrush.strokeDashArray = [10]
  //       fabricRef.current.freeDrawingBrush.fill = '#ff000082'
  //       fabricRef.current.freeDrawingBrush.id = 'pencil'
  //       break
  //   }
  // }

  // useHotkeys('ctrl', (event) => {
  //   event?.preventDefault()
  // })

  useEffect(() => {
    fabricRef.current = new Canvas('Draw', {
      erasable: true,
      isDrawingMode: true,
    })
    fabricRef.current.counter = 0

    const pathStrings = activeSegmentation?.paths

    const paths = pathStrings?.map(
      (pathString) =>
        new Path(pathString, {
          fill: 'red',
          strokeWidth: 2,
          opacity: 0.5,
        }),
    )

    paths?.forEach((path) => fabricRef.current.add(path))

    fabricRef.current.freeDrawingBrush = new PencilBrush(fabricRef.current)
    fabricRef.current.freeDrawingBrush.color = '#03a9f4'
    fabricRef.current.freeDrawingBrush.width = 1
    fabricRef.current.freeDrawingBrush.strokeDashArray = [10]
    fabricRef.current.freeDrawingBrush.fill = '#ff000082'
    fabricRef.current.freeDrawingBrush.id = 'pencil'
    fabricRef.current.on('object:modified', function () {
      updateModifications(true)
    })

    combineObjectsWithUnion(fabricRef)

    fabricRef.current.on('mouse:up', function () {
      if (!fabricRef.current.freeDrawingBrush) {
        return
      }
      if (fabricRef.current.freeDrawingBrush.id !== 'eraseMode') {
        fabricRef.current.getObjects().forEach((o) => {
          o.fill = '#ff0000c4'
          o.strokeDashArray = []
          o.stroke = '#ff0000c4'
          o.erasable = true
          o.selectable = true
        })
        updateModifications(true)
      }

      const newSegmentation = {
        ...activeSegmentation,
        paths: fabricRef.current.getObjects().map((o) => o.path?.join('')),
        dataUrl: fabricRef.current.toDataURL('image/png'),
        isLabeled: true,
        isFullyVisible: true,
      }

      setActiveSegmentation(newSegmentation)
      // }
      // fabricRef.current.renderAll()
    })
    fabricRef.current.on('mouse:moving', function () {
      if (fabricRef.current.freeDrawingBrush.id !== 'eraseMode') {
        fabricRef.current.getObjects().forEach((o) => {
          o.fill = '#ff0000c4'
          o.erasable = true
        })
      }
    })

    fabricRef.current.setWidth(dicom?.pixelWidth)
    fabricRef.current.setHeight(dicom?.pixelHeight)

    return () => fabricRef.current.dispose()
  }, [dicom?.pixelWidth, dicom?.pixelHeight, activeSegmentation, updateModifications, setActiveSegmentation])

  useEffect(() => setActiveMode('draw'), [activeFrameIndex])

  return (
    <SegmentationCanvasContainer scale={scale} tabIndex="1000">
      <WrapDrawControl>
        {/* <Button active={drawModeIsActive} onClick={handleDrawModeOnClick}>
          Draw
        </Button> */}
        {/* <Button active={eraseModeIsActive} onClick={handleEraseModeOnClick}>
          Eraser
        </Button> */}
        {/* <Button onClick={handleUndoOnClick}>Undo</Button> */}
        {/* <Button onClick={handleRedoOnClick}>Redo</Button> */}
      </WrapDrawControl>
      <DrawSection />
    </SegmentationCanvasContainer>
  )
}

export default SegmentationCanvas
