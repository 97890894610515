import { useCallback, useEffect, useRef } from 'react'
import { Canvas, Image, Text, Line } from 'fabric'

import { Calculate } from '../../../../../utilities'
import { KeypointerCanvasContainer, KeypointerCanvasSection } from './KeypointerCanvas.style'
import { CROSSHAIR_ICON } from '../../../../../constants/images'

const KeypointerCanvas = ({ activeKeypoints, activeFrame, dicom, scale, setKeypoints, showMeasurements }) => {
  const fabricRef = useRef(null)
  const points = activeKeypoints

  const handleKeypointerCanvasSectionOnClick = useCallback(
    (event) => {
      const isClickEvent = !event?.transform

      if (!isClickEvent) return

      const _activeKeypoints = window['keypoints']
      const activeKeypointIndex = _activeKeypoints?.findIndex((keypoint) => keypoint?.isActive)
      const noActiveKeyPoints = activeKeypointIndex < 0

      if (noActiveKeyPoints) return

      const previousKeypoints = _activeKeypoints?.slice(0, activeKeypointIndex)
      const nextKeypoint = _activeKeypoints[activeKeypointIndex]

      const newKeypoints = [
        ...previousKeypoints,
        {
          ...nextKeypoint,
          isLabeled: true,
          isActive: false,
          x: event.pointer.x,
          y: event.pointer.y,
          uuid: nextKeypoint?.uuid || Math.random().toString(36),
        },
        {
          ..._activeKeypoints[activeKeypointIndex + 1],
          isActive: !_activeKeypoints[activeKeypointIndex + 1]?.isLabeled,
        },
        ..._activeKeypoints.slice(activeKeypointIndex + 2),
      ]?.slice(0, _activeKeypoints?.length)

      setKeypoints(newKeypoints)
    },
    [setKeypoints],
  )

  const onMovingObj = useCallback(
    (event) => {
      const id = event?.target?.id

      var left = event?.target?.oCoords?.tl?.x
      var top = event?.target?.oCoords?.tl?.y

      const activeCrosshairText = fabricRef.current._objects.find((obj) => obj.point?.uuid === id)
      const activeStartLine = fabricRef.current._objects.find((obj) => obj.startPoint?.uuid === id)
      const activeEndLine = fabricRef.current._objects.find((obj) => obj.endPoint?.uuid === id)
      const activeStartLineText = activeStartLine?.text
      const activeEndLineText = activeEndLine?.text

      // add offset on drag:
      activeCrosshairText?.set({ left: left + 16, top: top + 16 })
      activeStartLine?.set({ x1: left + 9, y1: top + 9 })
      activeEndLine?.set({ x2: left + 9, y2: top + 9 })

      // recalculate line length:
      const startLinePx = Calculate.lineLength(
        left + 9,
        top + 9,
        activeStartLine?.endPoint?.x,
        activeStartLine?.endPoint?.y,
        dicom?.physicalDeltaX,
      ).toFixed(2)

      const endLinePx = Calculate.lineLength(
        activeEndLine?.startPoint?.x,
        activeEndLine?.startPoint?.y,
        left + 9,
        top + 9,
        dicom?.physicalDeltaX,
      ).toFixed(2)

      // update waterline text:
      activeStartLineText?.set({
        left: (left + 9 + activeStartLine?.endPoint?.x) / 2 + 5,
        top: (top + 9 + activeStartLine?.endPoint?.y) / 2,
        text: `${startLinePx}cm`,
      })

      activeEndLineText?.set({
        left: (activeEndLine?.startPoint?.x + left + 9) / 2 + 5,
        top: (activeEndLine?.startPoint?.y + top + 9) / 2,
        text: `${endLinePx}cm`,
      })

      fabricRef.current.renderAll()
    },
    [dicom?.physicalDeltaX],
  )

  const onObjectRelease = useCallback(
    (event) => {
      const isDragEvent = !!event?.transform

      if (!isDragEvent) return

      const targetClass = event?.target?.keyPointClass

      const activeKeypoint = activeKeypoints?.find((keypoint) => keypoint?.keyPointClass === targetClass)
      const otherKeyPoints = activeKeypoints?.filter((keypoint) => keypoint?.keyPointClass !== targetClass)
      const newKeypoints = [
        ...otherKeyPoints,
        {
          ...activeKeypoint,
          x: event?.target?.left,
          y: event?.target?.top,
        },
      ]

      setKeypoints(newKeypoints)
    },
    [activeKeypoints, setKeypoints],
  )

  useEffect(() => {
    if (fabricRef.current && fabricRef.current._objects) {
      fabricRef.current._objects.forEach((obj) => {
        fabricRef.current.remove(obj)
      })
      fabricRef.current.remove(...fabricRef.current.getObjects())
    }
    return () => {
      if (!JSON.stringify(window['keypoints'])) return
      const _arr = JSON.parse(JSON.stringify(window['keypoints']))
      const objs = fabricRef.current.getObjects()
      _arr.map((e) => {
        const _fabricobj = objs.find((c) => c.id === e.id)
        if (_fabricobj) {
          e.x = _fabricobj.left
          e.y = _fabricobj.top
        }
        return e
      })

      window['keypoints'] = _arr
      // setKeypoints(_arr)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFrame, fabricRef, showMeasurements])

  useEffect(() => {
    fabricRef.current = new Canvas('KeypointerCanvas')
    fabricRef.current.setWidth(dicom?.pixelWidth)
    fabricRef.current.setHeight(dicom?.pixelHeight)

    fabricRef.current.on('mouse:down', handleKeypointerCanvasSectionOnClick, false)
    fabricRef.current.on('object:moving', onMovingObj, false)
    fabricRef.current.on('mouse:up', onObjectRelease, false)

    const disposeFabric = () => fabricRef.current.dispose()

    return () => disposeFabric()
  }, [
    activeFrame,
    activeKeypoints,
    dicom?.pixelHeight,
    dicom?.pixelWidth,
    handleKeypointerCanvasSectionOnClick,
    onMovingObj,
    onObjectRelease,
    showMeasurements,
  ])

  useEffect(() => {
    window['keypoints'] = points
  }, [points, activeFrame])

  useEffect(() => {
    if (!points) return

    fabricRef.current._objects.forEach((obj) => fabricRef.current.remove(obj))
    fabricRef.current.remove(...fabricRef.current.getObjects())
    fabricRef.current.defaultCursor = 'pointer'

    const crosshairIcon = document.getElementById('crosshair')

    const renderablePoints = points.filter((e) => e.x && e.y && e.isLabeled)

    renderablePoints?.forEach((point) => {
      const crosshair = new Image(crosshairIcon, {
        radius: 7,
        originX: 'center',
        originY: 'center',
        left: point?.x,
        top: point?.y,
        fill: 'red',
        id: point?.uuid,
        keyPointClass: point?.keyPointClass,
      })

      const crosshairText = new Text(`${point?.order}`, {
        left: point.x + 7,
        top: point.y + 7,
        fontSize: 12,
        selectable: false,
        fontWeight: 'bold',
        fill: 'yellow',
        point: point,
      })

      const keyPointPair = renderablePoints.find((keyPoint) => point?.keyPointPair === keyPoint?.keyPointClass)

      fabricRef.current.add(crosshair)
      fabricRef.current.add(crosshairText)

      crosshair.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false,
        bl: false,
        br: false,
        tl: false,
        tr: false,
        mtr: false,
      })

      if (!keyPointPair) return

      const waterLine = new Line([keyPointPair?.x, keyPointPair?.y, point?.x, point?.y], {
        stroke: 'yellow',
        strokeWidth: 2,
        originX: 'center',
        originY: 'center',
        selectable: false,
        strokeDashArray: [10, 5],
        startPoint: keyPointPair,
        endPoint: point,
      })

      var px = Calculate.lineLength(point.x, point.y, keyPointPair.x, keyPointPair.y, dicom?.physicalDeltaX).toFixed(2)

      var text = new Text('' + parseFloat(px).toFixed(2) + 'cm', {
        left: (point.x + keyPointPair.x) / 2 + 5,
        top: (point.y + keyPointPair.y) / 2,
        fontSize: 12,
        selectable: false,
        fontWeight: 'bold',
        fill: 'yellow',
      })

      // add text to the line:
      waterLine.text = text

      if (showMeasurements) fabricRef.current.add(text)

      fabricRef.current.add(waterLine)
    })
  }, [points, activeFrame, showMeasurements, dicom?.physicalDeltaX])

  return (
    <KeypointerCanvasContainer scale={scale} tabIndex="1000">
      <KeypointerCanvasSection />
      <img src={CROSSHAIR_ICON} alt="crosshair" id="crosshair" width="15px" height="15px" hidden />
    </KeypointerCanvasContainer>
  )
}

export default KeypointerCanvas
